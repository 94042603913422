"use strict";

let __SWNS = {};
(($, win, doc)=> {    // JQ ES6 sw Pack

  let $win = $(win),
      $doc = $(doc),
      swns = {
        theDate() {
          let today = new Date();
          let day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          let y = today.getFullYear();
          let m = today.getMonth() + 1;
          let d = today.getDate();
          let w = today.getDay();
          let H = today.getHours();
          let M = today.getMinutes();
          let S = today.getSeconds();
          if (m < 10) { m = "0" + m; }
          if (d < 10) { d = "0" + d; }
          if (H < 10) { H = "0" + H; }
          if (M < 10) { M = "0" + M; }
          if (S < 10) { S = "0" + S; }
          return `${day[w]}:` + m + "/" + d + "/" + H + ":" + M + "." + S;
        },
        getUrlVars() {
          let vars = [];
          let max = 0;
          let hash = '';
          let array = '';
          let url = window.location.search;
          hash = url.slice(1).split('&');
          max = hash.length;
          let i = 0;
          while (i < max) {
            array = hash[i].split('=');
            /*fixup 日本語にデコードUTF8
            *  ※ すべての文字がエンコードされるので、/tatsuya/js/index.htm などのパスを表す文字列には注意が必要です。
              ※ スラッシュ (/) もエンコードされるので、Web サーバーへの要求として送信する場合は無効になります。
              ※ 文字列に URI コンポーネントが複数含まれる場合は、encodeURI 関数を使用します。
              */
            //vars.push(array[0]);
            vars.push( decodeURIComponent(array[0]) );
            //vars[array[0]] = array[1];
            vars[array[0]] = decodeURIComponent(array[1]);
            i++;
          }
          return vars;
        }
      }; // swns{}

  /* animateCallback
  * # http://takustaqu.hatenablog.com/entry/2014/03/02/025558
    # http://qiita.com/nekoneko-wanwan/items/e8114c6e34d2950a7b28
    # animationstart（delay完了後）
  */
  $.fn.animateStartCallback = function(callback) {
    let alias;
    alias = 'animationstart webkitAnimationStart';
    return this.each(function() {
      $(this).on(alias, function() {
        return callback.call(this);
      });
    });
  };
  $.fn.animationIterationCallback = function(callback) {
    let alias;
    alias = 'animationiteration webkitAnimationIteration';
    return this.each(function() {
      $(this).on(alias, function() {
        $(this).off(alias);  // sw on/off(unbind is .one())
        $(this).off(alias);
        return callback.call(this);
      });
    });
  };
  $.fn.animateEndCallback = function(callback) {
    let alias;
    alias = 'animationend webkitAnimationEnd';
    return this.each(function() {
      $(this).on(alias, function() {
        $(this).off(alias);
        return callback.call(this);
      });
    });
  };
  /* transitionCallback */
  $.fn.transitionStartCallback = function(callback) {
    let alias;
    alias = 'transitionstart webkitTransitionStart';
    return this.each(function() {
      $(this).bind(alias, function() {
        $(this).unbind(alias);
        return callback.call(this);
      });
    });
  };
  $.fn.transitionEndCallback = function(callback) {
    let alias;
    alias = 'webkitTransitionEnd transitionend';
    return this.each(function() {
      $(this).bind(alias, function() {
        $(this).unbind(alias);
        return callback.call(this);
      });
    });
  };

  // do it DRF
  $(()=> {

    // ?catnum=6&placenum_post_tag=tag_2&placenum_kaiinn_seitag=&low=ひくい&high=たかい&kodawari%5B%5D=2%E9%9A%8E%E4%BB%A5%E4%B8%8A%E4%BD%8F%E6%88%B8&submit=%E6%A4%9C%E7%B4%A2
    let getUrlVarsVal = [];
    getUrlVarsVal = swns.getUrlVars();
    console.info("[_sw.js Ready] getQuery", getUrlVarsVal);  // #------- sw Log --(´･_･`)


  }); // DRF }
})(jQuery, window, document);
